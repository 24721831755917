import Button from "@/components/button";
export default {
    name: "bulk-upload-valid",
    components: {
        "neo-button": Button,
    },
    props: {
        table_data: {
            type: Array,
            default: [],
        },
        // errors: {
        //   type: Array
        // },
        // filename: {
        //   type: String,
        //   default: ''
        // }
    },
    data() {
        return {};
    },
    computed: {
        isErrors() {
            return this.table_data?.some((dt) => dt.error_list.length > 0);
            // return (this.errors.length > 0)
        },
        title() {
            return `Uploaded Excel File(s)`;
        },
    },
    mounted() {},
    methods: {
        invError(row, col, error_list, tooltip = false) {
            const getErrors = (errors) => {
                let res = [];
                errors.forEach((err, index) => {
                    let elemInd = res.findIndex((ele) => err.row === ele.row && err.col === ele.col);
                    if (elemInd === -1) res.push(err);
                    else res[elemInd].error_message = err.error_message + " AND " + res[elemInd].error_message;
                });
                return res;
            };
            let erErrors = getErrors(error_list);

            // check for errors
            for (let i = 0; i < erErrors.length; i++) {
                const error = erErrors[i];
                if (error["row"] === row && error["col"] === col) {
                    let error_message = error["error_message"];
                    // if tooltip return error message else return true for highlighting
                    if (tooltip) return error_message;
                    else return true;
                }
            }
            return false;
        },
        cancelUpload() {
            this.$emit("cancelUpload");
            this.$modal.hide("upload_xl_valid");
        },
        submitFurther() {
            /// do upload here to source
            if (this.isErrors) {
                this.$toast.info("Please clear all errors and try uploading it again!");
                return;
            } else {
                this.$emit("submitUpload");
            }
        },
    },
};
