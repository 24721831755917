<template>
    <modal :name="name" @before-close="handleOutsideClick" @before-open="outsideClick = true">
        <div class="filters-modal tw-h-full tw-flex tw-flex-col tw-justify-between">
            <div class="tw-px-6 tw-pt-4 tw-grow tw-flex tw-flex-col">
                <div class="tw-flex tw-justify-between tw-py-2">
                    <b class="tw-text-black" v-text="title" />
                    <font-awesome-icon icon="times" class="tw-cursor-pointer" @click="close()"> </font-awesome-icon>
                </div>

                <div class="tw-my-4 tw-grow">
                    <slot />
                </div>
            </div>

            <div class="tw-flex tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-px-6 tw-py-4 tw-bg-white">
                <button @click="applyFilters" class="tw-border-none tw-h-10 tw-w-20 tw-ml-auto tw-bg-blue-600 tw-cursor-pointer hover:tw-bg-blue-700 tw-text-white tw-py-2 tw-rounded-lg tw-text-base">
                    Save
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import NeoButton from "@/components/button";

export default {
    components: {"neo-button": NeoButton},
    model: {prop: "isModalOpen", event: "isModalOpen:update"},
    /**
     * @property
     * dynamic properties passed to the component with default value
     */
    props: {
        title: String,
        isModalOpen: Boolean,
        name: {type: String, required: true},
    },
    data() {
        return {outisdeClick: true};
    },
    computed: {
        /**
         * @property
         * vModel - bind the input getters and setters on component
         */
        vModel: {
            get() {
                return this.isModalOpen;
            },
            set(val) {
                this.$emit("isModalOpen:update", val);
            },
        },
    },
    watch: {
        vModel(val) {
            this.$modal[val ? "show" : "hide"](this.name);
        },
    },
    methods: {
        /**
         * @method
         * @name open - function to open the popup
         */
        open() {
            this.vModel = true;
        },
        /**
         * @method
         * @name close - function to close the popup
         */
        close() {
            this.$emit("close");
            this.vModel = false;
        },
        /**
         * @method
         * @name handleOutsideClick - function to revert the checkbox & close the popup when clicked outside
         */
        handleOutsideClick() {
            if (this.outsideClick) this.close();
        },
        /**
         * @method
         * @name applyFilters - function to search data as per selected filters
         */
        applyFilters() {
            this.outsideClick = false;
            this.$emit("applyFilters");
            this.vModel = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.tw-grow {
    flex-grow: 1;
}

.bg-blue {
    background-color: #0d69d5;
}

.shadow-top {
    box-shadow: 0 -2px 5px #00000050;
}
</style>
