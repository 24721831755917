import {DateTime} from "luxon";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";

export default {
    name: "adverse-media-resources",
    props: ["mediaResources"],
    components: {
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
    },
    methods: {
        parseDate(dt, format = "EEEE, dd LLLL yyyy") {
            return DateTime.fromISO(dt).toFormat(format);
        },
    },
};
